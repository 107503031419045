import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableRow, TableHeader, TableCell, TableBody } from 'grommet';

const DynamicTable = ({ blok }) => {
  return (
    <Table>
      <TableHeader>
        {(blok.content?.thead || []).map((h) => (
          <TableCell key={h.value}>{h.value}</TableCell>
        ))}
      </TableHeader>
      <TableBody>
        {(blok.content?.tbody || []).map((r, i) => (
          <TableRow key={i}>
            {(r.body || []).map((c) => (
              <TableCell key={c.value}>{c.value}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

DynamicTable.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default DynamicTable;
